import { Component } from '@angular/core';
import { Observable } from 'rxjs';

import { ILoan } from '../../classes/loan';
import { SharedService } from '@app/services/shared.service';
import { Store, select } from '@ngrx/store';
import { getCurrentLoanId, getLoans } from '@app/store/scenario';
import { RemoveLoan } from '@app/store/loan';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'app-loan-list',
    templateUrl: './loan-list.component.html',
    standalone: false
})
export class LoanListComponent {

  loans$: Observable<ILoan[]> = this.store.select(getLoans);
  loans: ILoan[] = [];
  loanId: string;

  constructor(
    private store: Store,
    private sharedService: SharedService) { 
      this.store.pipe(
        select(getCurrentLoanId),
        takeUntilDestroyed()
      ).subscribe(id => {
        this.loanId = id;
      });
    }

  view(selloan): void {
    this.sharedService.changeLoan(selloan);
  }

  remove(loan) {
    this.store.dispatch(RemoveLoan(loan.id));
  }

}
