import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Observable, Subject, takeUntil } from 'rxjs';
import { ILoan } from '../../classes/loan';
import { SharedService } from '@app/services/shared.service';
import { IScenario } from '@models';
import { Store } from '@ngrx/store';
import { getScenarios } from '@app/store/scenario';
import { BaseChartDirective } from 'ng2-charts';
import { Scenario } from 'src/classes/scenario';
import { CurrencyPipe } from '@angular/common';

@Component({
    selector: 'app-scenario-graph',
    templateUrl: './scenario-graph.component.html',
    styleUrls: ['./scenario-graph.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ScenarioGraphComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  @ViewChild('label3d') labelReference: ElementRef;
  @ViewChild('infos') infosReference: ElementRef;
  @ViewChild('dyg') dyGraphRef: ElementRef;
  @Input() scenario: any[];
  scenarios$: Observable<IScenario[]> = this.store.select(getScenarios);
  loans: ILoan[] = [];
  scenarioEx: Scenario;
  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;
  data: any[];
  options = {
    width: 'auto',
    height: '220',
    labels: ['', '', '', ''],
    strokeWidth: 1.5,
    logscale: false,
    //customBars: true,
    colors: ['#d93523', '#29d523aa','#20dd33aa','#20dd33aa'],
    stackedGraph: false,
    showRangeSelector: true, pointSize: 2,
    drawCallback: this.drawCallback.bind(this),
    highlightCallback: this.mouseOverGraph.bind(this),
    unhighlightCallback: this.mouseOutGraph.bind(this)
  }
  isOpen: boolean;
  mouse: { x: number, y: number } = { x: 0, y: 0 };

  constructor(
    private store: Store,
    private angularRenderer: Renderer2,
    private cd: ChangeDetectorRef,
    private elRef: ElementRef,
    private ngZone: NgZone,
    private sharedService: SharedService) { }

  ngOnInit(): void {
    this.sharedService.scenarioEx.pipe(
      takeUntil(this.destroy$)
    ).subscribe((scenarioEx) => {
      // console.log(scenarioEx);
      this.scenarioEx = scenarioEx;
      if (scenarioEx !== null) {
        // console.log(scenarioEx);
        const rows = [] as any[];
        // console.log(scenarioEx.rows);
        for (const row of scenarioEx.rows) {
          rows.push([new Date(row.dto.dateMk), ...row.graph]);
        }
        this.data = rows;
        this.cd.markForCheck();
      }
    });
    this.elRef.nativeElement.addEventListener('mousemove', this.onMouseMove.bind(this), false);
  }

  ngOnDestroy(): void {
    this.elRef.nativeElement.removeEventListener('mousemove', this.onMouseMove);
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  mouseOverGraph(ev, x, points, row, seriesName) {
    
    // this.ngZone.run(() => this.isOpen = true);
    this.isOpen = true;
    // console.log(ev, x, points, row, seriesName, this.isOpen);
    // console.log(this.scenarioEx.rows[row]);

    const item = this.scenarioEx.rows[row];
    const balance = item.balance.toFixed(2);
    const httpText = `
    <div><strong>Palette</strong>: ${item.barcode} </div>
    <div><strong>No. Item</strong>: ${item.upc} </div>
    <div><strong>Nom</strong>: ${item.name} </div>
    <div><strong>Balance</strong>: ${balance} </div>
    `;
    this.angularRenderer.setProperty(this.infosReference.nativeElement, 'innerHTML', httpText);
  }

  mouseOutGraph(ev) {
    // console.log('mouseOut', ev);
    this.isOpen = false;
  }

  onMouseMove(event: MouseEvent) {
    event.preventDefault();
    this.mouse.x = event.clientX;
    this.mouse.y = event.clientY;
    // console.log(this.isOpen, event);
    if (this.isOpen) {
      this.angularRenderer.addClass(this.labelReference.nativeElement, 'show');
      this.angularRenderer.setStyle(this.labelReference.nativeElement, 'left', (this.mouse.x + 8) + 'px');
      this.angularRenderer.setStyle(this.labelReference.nativeElement, 'top', (this.mouse.y + 14) + 'px');
    } else {
      this.angularRenderer.removeClass(this.labelReference.nativeElement, 'show');
    }
    // console.log('mouse', this.mouse);
  }

  drawCallback(ev) {
    this.toggleLoader(false);
  }

  toggleLoader(visible: boolean) {
    const style = visible ? 'flex' : 'none';
    const loader = this.dyGraphRef.nativeElement.querySelector('.loader-holder');
    // console.log('drawCallback', this.dyGraphRef.nativeElement, loader);
    this.angularRenderer.setStyle(loader, 'display', style);
  }

  view(selloan): void {
    this.sharedService.changeLoan(selloan);
  }


}
