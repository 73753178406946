import { Component, OnDestroy, OnInit } from '@angular/core';
import { SharedService } from '@app/services/shared.service';
import { Subject, takeUntil } from 'rxjs';

import { ILoan, Loan } from '../../../../classes/loan';

@Component({
    selector: 'app-loan-stats',
    templateUrl: './loan-stats.component.html',
    standalone: false
})
export class LoanStatsComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  loanEx: Loan | null = null;

  constructor(private sharedService: SharedService) { }

  ngOnInit(): void {
    this.sharedService.loanEx.pipe(
      takeUntil(this.destroy$)
    ).subscribe((loanEx) => {
        this.loanEx = loanEx;
        // console.log(this.loan);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  showAmortization(amort): string {
    // console.log(amort);
    return amort.years + ' years ' + amort.periods + ' periods';
  }

}
