import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { getUser } from '@app/store/auth';
import { AuthService } from '@app/services/auth.service';
import { AuthTokenService } from '@app/services/auth-token.service';
import { ElementType } from 'src/models/element-type.enum';
import { AddElement } from '@app/store/scenario';

@Component({
    selector: 'app-dev-frame',
    templateUrl: './dev-frame.component.html',
    standalone: false
})
export class DevFrameComponent implements OnInit {
  user$ = this.store.pipe(select(getUser));
  token: any;
  subscription: Subscription | null = null;
  email: string;

  constructor(
    private dialog: MatDialog,
    private authService: AuthService,
    private tokenSerive: AuthTokenService,
    private store: Store) { }


  ngOnInit(): void {
    this.tokenSerive.token$.subscribe((token) => {
      this.token = token;
    });
  }

  addLoan() {

  }

  addTaxes() {
    this.store.dispatch(AddElement({ amount: 2580, type: ElementType.Tax }));
  }

}
