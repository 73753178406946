import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IScenario } from '@models';
import { select, Store } from '@ngrx/store';
import { getCurrentScenarioId, getScenarios, SelectScenario } from '@app/store/scenario';

@Component({
    selector: 'app-scenario-list',
    templateUrl: './scenario-list.component.html',
    standalone: false
})
export class ScenarioListComponent {
  scenarios$: Observable<IScenario[]> = this.store.select(getScenarios);
  scenarioId: number | string;

  constructor(
    private store: Store) { 
      this.store.pipe(
        select(getCurrentScenarioId),
        takeUntilDestroyed()
      ).subscribe(id => this.scenarioId = id);
    }

  view(scenario): void {
    this.store.dispatch(SelectScenario(scenario));
  }

}
