import { CommonModule } from '@angular/common';
import { Component, inject, OnInit, ViewEncapsulation } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-language',
    imports: [CommonModule],
    template: `<div class="dropdown" tab-index="0">
                    <button id="dropdown-btn"></button>
                    <ul class="dropdown-content" id="dropdown-content"></ul>
                </div>`,
    styleUrls: ['language.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class LanguageComponent implements OnInit {
    // locales = ["en-GB", "ar-SA", "zh-CN", "de-DE", "es-ES", "fr-FR", "hi-IN", "it-IT", "in-ID", "ja-JP", "ko-KR", "nl-NL", "no-NO", "pl-PL", "pt-BR", "sv-SE", "fi-FI", "th-TH", "tr-TR", "uk-UA", "vi-VN", "ru-RU", "he-IL"];

    langOpen = false;
    locales = [
        { id: 1, name: 'en', desc: 'English (US)', flag: 'flag-us', region: 'en-GB' },
        { id: 2, name: 'fr', desc: 'Francais', flag: 'flag-fr', region: 'fr-FR' },
        { id: 3, name: 'zh', desc: '中文', flag: 'flag-cn', region: 'zh-CN' }
    ];
    selectedLang = null;

    private readonly translate = inject(TranslateService);
    constructor(
        private _adapter: DateAdapter<any>) { }

    ngOnInit(): void {
        this.selectedLang = this.locales[0];
        this._adapter.setLocale(this.locales[0].name);

        this.setSelectedLocale(this.locales[0]);
        const browserLang = 'en';

        for (const locale of this.locales) {
            const localeLang = locale.name;
            if (localeLang === browserLang) {
                this.setSelectedLocale(locale);
            }
        }
    }

    changeLanguage(pays): void {
        this.selectedLang = pays;
        this.translate.use(pays.name);
        this._adapter.setLocale(pays.name);
        this.langOpen = false;
    }

    getFlagSrc(countryCode) {
        return /^[A-Z]{2}$/.test(countryCode)
            ? `https://flagsapi.com/${countryCode.toUpperCase()}/shiny/64.png`
            : "";
    }

    setSelectedLocale(locale) {
        const dropdownBtn = document.getElementById("dropdown-btn");
        const dropdownContent = document.getElementById("dropdown-content");

        dropdownContent.innerHTML = "";

        const otherLocales = this.locales.filter((loc) => loc !== locale);
        otherLocales.forEach((otherLocale) => {
            const listEl = document.createElement("li");
            // console.log(locale, otherLocale.region.substring(otherLocale.region.length - 2));
            listEl.innerHTML = `${otherLocale.desc}<img src="${this.getFlagSrc(otherLocale.region.substring(otherLocale.region.length - 2))}" />`;
            // listEl.value = otherLocale;
            listEl.addEventListener("mousedown", () => {
                this.setSelectedLocale(otherLocale);
            });
            dropdownContent.appendChild(listEl);
        });

        this.changeLanguage(locale);
        dropdownBtn.innerHTML = `<img src="${this.getFlagSrc(locale.region.substring(locale.region.length - 2))}" />${locale.desc}<span class="arrow-down"></span>`;
    }




}
