import { Component } from '@angular/core';

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    standalone: false
})
export class MainComponent {

  constructor() {}

}
