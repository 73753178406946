import { Component, OnInit } from '@angular/core';
import { APP_CONFIG } from '../../../app.config';
import metaInfo from '../../../../../metadata.json';
const { version, build } = metaInfo;

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    standalone: false
})
export class FooterComponent {
  copyright = APP_CONFIG.copyright;
  appVersion = 'v' + version + ' build ' + build;
  isMobile = APP_CONFIG.isMobile;
  userAgent = APP_CONFIG.userAgent;

  constructor() { }

}

