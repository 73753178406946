import { Component, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { Loan } from '../../../../classes/loan';
import { SharedService } from '@app/services/shared.service';
import { AddElement, getScenarios } from '@app/store/scenario';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { Scenario } from 'src/classes/scenario';
import { Router } from '@angular/router';
import { IScenario } from '@models';

import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';

@Component({
    selector: 'app-element-form',
    templateUrl: './element-form.component.html',
    styleUrls: ['./element-form.component.css'],
    standalone: false
})
export class ElementFormComponent implements OnDestroy {
  private destroy$ = new Subject<boolean>();
  form: UntypedFormGroup;
  fset1 = true;
  fset2 = false;
  tempDoc: any;
  loans: Loan[] = [];
  scenario: Scenario;
  scenarios$: Observable<IScenario[]> = this.store.select(getScenarios);

  constructor(
    fb: UntypedFormBuilder,
    private store: Store,
    private router: Router,
    private sharedService: SharedService,
    translate: TranslateService) {
    translate.addLangs(['en', 'fr']);
    // Specifies the current languages to the service
    translate.use('fr');
    this.form = fb.group({
      amount: ['', Validators.required],
      type: [null],
      scenario: [null],
      startsAt: [null],
      freqAmount: [null],
      freqType: [null],
      amort: [null]
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }


  calculateNew(year: number, locateid: number): boolean {
    if (!this.validateFields()) {
      return false;
    }
    return false;
  }

  async submit(value: any) {
    let frmDate = new Date();

    if (!this.form.valid) {
      console.log('invalid form', this.form);
    }

    value.id = uuidv4();
    if (value.startsAt !== null && typeof (value.startsAt) === 'object') {
      console.log(value);
      value.startsAt = dayjs(value.startsAt).format('YYYY-MM-DD');
    }
    // console.log(value);

    // this.scenario.addLoan();
    this.store.dispatch(AddElement(value));

    // this.sharedService.changeLoan(value);
    this.router.navigate(['dashboard']);
  }

  validateFields(): boolean {
    return true;
  }

  closeForm() {
    this.sharedService.closeForms();
  }
}
