import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ILoan, Loan } from '../../classes/loan';

import { SharedService } from '@app/services/shared.service';
import { AuthService } from '@app/services/auth.service';
import { Router } from '@angular/router';
import { IScenario } from '@models';
import { ElementType } from 'src/models/element-type.enum';
import { LoadScenarios, SelectScenario } from '@app/store/scenario';
import { Store } from '@ngrx/store';


@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    standalone: false
})
export class HomeComponent {
  loan: Loan | null = null;
  /*
    scenarios = [
      { hypotheque: 195000, rate: 2.95, freq: 'q', years: 25, dtdebut: '2017-05-01' },
      { hypotheque: 254000, rate: 2.95, freq: 'm', years: 25, dtdebut: '2011-06-20' },
      { hypotheque: 348000, rate: 2.95, freq: 'b', years: 25, dtdebut: '2002-04-19' },
      { hypotheque: 160000, rate: 2.95, freq: 'w', years: 25, dtdebut: '1997-07-03' }
    ];
  */

  scenarios = [
    {
      name: '3 Properties, 2 Incomes',
      description: '',
      startsAt: '2006-12-01',
      id: '8h42cbf8-d913-4c29-b06c-b0d021ea964e',
      elements: [
        { type: ElementType.Mortgage, hypotheque: 195000, rate: 2.95, freq: 'q', years: 25, startsAt: '2007-01-01', id: '5d30cbf8-d913-4c29-b06c-b0d021ea964e' },
        { type: ElementType.Mortgage, hypotheque: 228400, rate: 2.5, freq: 'm', years: 25, startsAt: '2022-05-01', id: '8b2a2b87-bc74-4834-84b7-405c1d8a253f' },
        { type: ElementType.Mortgage, hypotheque: 350000, rate: 3, freq: 'm', years: 25, startsAt: '2028-01-01', id: '3b2a2b87-bc74-4834-84b7-405c1d8a253f' },
        { type: ElementType.Income, id: 'd2d534ee-f8de-45b4-91d1-f94c84f7e7df', amount: 1880, freqAmount: '2', freqType: 'q', startsAt: '2007-01-01', endsAt: '2017-04-30' },
        { type: ElementType.Income, id: '9197bd27-81e9-4704-ae17-735259c7177c', amount: 2120, freqAmount: '2', freqType: 'q', startsAt: '2017-05-01' },
        { type: ElementType.Expense, id: 'f249becd-d42a-4a0e-8c61-1037aa2053bb', name: 'taxes municipales', amort: true, freqAmount: '1', freqType: 'y', amount: 3146.6, startsAt: '2017-05-01', mortgage: '5d30cbf8-d913-4c29-b06c-b0d021ea964e' },
        { type: ElementType.Expense, id: 'bb3a852c-82f8-48a1-b09b-1d8b8cfdcaad', name: 'taxes bienvenue', freqAmount: '1', freqType: 'y', amount: 1286.54, startsAt: '2017-05-01', endsAt: '2017-06-01', mortgage: '5d30cbf8-d913-4c29-b06c-b0d021ea964e' },
        { type: ElementType.Expense, id: '6d56fa5d-cc62-4c7e-bebd-de85a7d18211', name: 'Power bill', amort: true, freqAmount: '1', freqType: 'y', amount: 2250, startsAt: '2017-05-01', mortgage: '5d30cbf8-d913-4c29-b06c-b0d021ea964e' },
      ]
    },
    { hypotheque: 195000, rate: 2.95, freq: 'q', years: 25, dtdebut: '2017-05-01' },
    { hypotheque: 254000, rate: 2.95, freq: 'm', years: 25, dtdebut: '2011-06-20' },
    { hypotheque: 348000, rate: 2.95, freq: 'b', years: 25, dtdebut: '2002-04-19' }
  ];

  constructor(
    private router: Router,
    private store: Store,
    private translate: TranslateService,
    private sharedService: SharedService,
    private auth: AuthService) { 
      
    }

  isAdjusment(): boolean {
    if (this.loan && this.loan.rates !== undefined) {
      return true;
    }

    return false;
  }

  calculateScenario(scenario: IScenario): void {
    console.log('calculateScenario', scenario);
    let locale = '';
    if (this.translate.currentLang) {
      locale = this.translate.currentLang + '/';
    }
    this.store.dispatch(LoadScenarios([scenario]));
    // this.store.dispatch(SelectScenario(scenario));
    this.router.navigate([locale + 'dashboard']);
  }

}
