import { Component } from '@angular/core';
import { Subscription } from 'rxjs';

import { MatDialog } from '@angular/material/dialog';
import { LoginModalComponent } from '@app/shared/layout/login/login-modal.component';
import { select, Store } from '@ngrx/store';
import { getUser, LogoutAction } from '@app/store/auth';
import { AuthService } from '@app/services/auth.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    standalone: false
})
export class HeaderComponent {
  user$ = this.store.pipe(select(getUser));
  subscription: Subscription | null = null;
  email: string;

  constructor(
    private dialog: MatDialog,
    private authService: AuthService,
    private store: Store) { }

  loginShown(): void {
    const modal = document.querySelector('.modal-dialog') as HTMLElement;
    modal.style.top = '100px';
  }

  showLogin(e: Event): void {
    e.preventDefault();
    const dialogRef = this.dialog.open(LoginModalComponent, {
      width: '300px',
      height: '414px',
      panelClass: 'login-modal',
      position: { top: '128px' },
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.email = result;
    });

  }

  logout(e: Event): void {
    e.preventDefault();
    this.store.dispatch(LogoutAction());
  }
}
