import {
    ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output,
    ViewChild
} from '@angular/core';
import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { debounceTime, fromEvent, share, startWith, Subject, switchMap, switchMapTo, takeUntil } from 'rxjs';
import { filter } from 'rxjs';

@Component({
    selector: 'app-popup',
    templateUrl: './popup.component.html',
    styleUrls: ['./popup.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PopupComponent implements OnDestroy, OnInit {
    @Input() CdkOverlayOrigin: CdkOverlayOrigin;
    @Output() popclose = new EventEmitter<any>();
    @Output() popopen = new EventEmitter<any>();

    @ViewChild('dialog') dialog: ElementRef;
    isOpened = false;
    destroy$ = new Subject<any>();

    constructor(private changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        const CdkOverlayOriginEl = this.CdkOverlayOrigin.elementRef.nativeElement;

        // open popup if mouse stopped in CdkOverlayOriginEl (for short time).
        // If user just quickly got over CdkOverlayOriginEl element - do not open
        const open$ = fromEvent(CdkOverlayOriginEl, 'mouseenter').pipe(
            filter(() => !this.isOpened),
            switchMap(enterEvent =>
                fromEvent(document, 'mousemove').pipe(
                    startWith(enterEvent),
                    debounceTime(300),
                    filter(event => CdkOverlayOriginEl === event['target'])
                )),
            share());

        open$.pipe(
            takeUntil(this.destroy$)
        ).subscribe(() => this.changeState(true));

        // close if mouse left the CdkOverlayOriginEl and dialog(after short delay)
        const close$ = fromEvent(document, 'mousemove').pipe(
            debounceTime(100),
            filter(() => this.isOpened),
            filter(event => this.isMovedOutside(CdkOverlayOriginEl, this.dialog, event))
        );

        open$.pipe(
            takeUntil(this.destroy$),
            switchMapTo(close$)
        ).subscribe(() => {
            this.changeState(false);
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    connectedOverlayDetach() {
        this.changeState(false);
    }

    private changeState(isOpened: boolean) {
        this.isOpened = isOpened;
        isOpened ? this.popopen.emit() : this.popclose.emit();
        this.changeDetectorRef.markForCheck();
    }

    private isMovedOutside(CdkOverlayOriginEl, dialog, event): boolean {
        return !(CdkOverlayOriginEl.contains(event['target']) || dialog.nativeElement.contains(event['target']));
    }
}
