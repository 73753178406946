import { AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter, Inject, Input, LOCALE_ID, Output } from '@angular/core';
import { formatNumber } from '@angular/common';

@Component({
    selector: 'app-grid',
    templateUrl: './grid.component.html',
    styleUrls: ['./grid.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class GridComponent implements AfterViewInit {
  @Input() columns: string[];
  @Input() rows: any[];

  /**
   * A cell or row was focused via keyboard or mouse click.
   */
  @Output() activate: EventEmitter<any> = new EventEmitter();

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private cd: ChangeDetectorRef
    ) { }

  ngAfterViewInit(): void {
    this.cd.markForCheck();
  }

  clickRow(event: MouseEvent, row): void {
    this.activate.emit({
      type: 'click',
      event,
      row
    });
  }

  // TODO: #3 Mettre les 3 types transactions dans l'objet row
  formatCellValue(row, col): any {
    // console.log(col);
    const cell = row[col.prop];
    if (col.type !== undefined && col.type === 'number') {
      if (col.prop === 'pm' && row.pc) {
        // console.log('row', row);
        // return '<div class="payment-changed">' + formatNumber(cell, this.locale, '1.2-2') + '</div>';
      }
      return formatNumber(cell, this.locale, '1.2-2');
    }

    return cell;
  }

  trackByFn(index, item) {
    return item.id;
  }

  public getStyles(): any {
    // console.log('columns', this.columns.length, this.columns);
    return {
      display: 'grid',
      'align-items': 'center',
      'grid-template-rows': `36px 1fr`,
      'grid-template-columns': `repeat(${this.columns.length}, 1fr)`,
    };
  }

  public getStylesSpaned(): any {
    return {
      display: 'grid',
      'grid-column': `span 8`,
    };
  }

  public getBodyStyles(): any {
    return {
      display: 'grid',
      'grid-column': '1 / ' + (this.columns.length + 1),
      'grid-template-columns': `repeat(${this.columns.length}, 1fr)`,
    };
  }
}
