import { Component, OnInit } from '@angular/core';
import { SharedService } from '@app/services/shared.service';
import { UpdateLoan } from '@app/store/loan';
import { UpdateScenario } from '@app/store/scenario';
import { Store } from '@ngrx/store';
import { ILoan, Loan } from 'src/classes/loan';

@Component({
    selector: 'app-loan-updates',
    templateUrl: './loan-updates.component.html',
    styleUrls: ['./loan-updates.component.scss'],
    standalone: false
})
export class LoanUpdatesComponent implements OnInit {
  loan: ILoan;

  constructor(
    private sharedService: SharedService,
    private store: Store) { }

  ngOnInit(): void {
    this.sharedService.loan.subscribe((loan) => {
      this.loan = loan;
  });
  }

  isAdjusment(): boolean {
    if (this.loan && this.loan.transactions !== undefined) {
      return true;
    }

    return false;
  }

  remove(transaction) {
    const transactions = [...this.loan.transactions].filter(x => x !== transaction);
    this.store.dispatch(UpdateLoan({ id: this.loan.id, changes: { transactions }}));
  }
}
