import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { forkJoin, Observable, Subject, Subscription, take, takeUntil } from 'rxjs';

import { ILoan, Loan } from '../../../../classes/loan';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '@app/services/shared.service';
import { MatDialog } from '@angular/material/dialog';
import { TransactionModalComponent } from '../transaction-modal/transaction-modal.component';
import { Store } from '@ngrx/store';
import { columns } from './columns';
import { TransactionType } from 'src/models/loan-enums.model';
import { UpdateLoan } from '@app/store/loan';
import { Scenario } from 'src/classes/scenario';
import { IScenario } from '@models';

@Component({
    selector: 'app-scenario-detail',
    templateUrl: './scenario-detail.component.html',
    styleUrls: ['scenario-detail.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ScenarioDetailComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  scenarioEx: Scenario;
  scenario: IScenario;
  currentPage = 1;
  // totalItems: number;
  pageSize = 18;
  maxSize = 8;

  constructor(
    private sharedService: SharedService,
    private translate: TranslateService,
    private store: Store,
    private cd: ChangeDetectorRef,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.sharedService.scenarioEx.pipe(
      takeUntil(this.destroy$)
    ).subscribe((scenarioEx) => {
      this.scenarioEx = scenarioEx;
      this.cd.markForCheck();
    });
    this.sharedService.scenario.pipe(
      takeUntil(this.destroy$)
    ).subscribe((scenario) => {
      this.scenario = scenario;
      this.cd.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  get columns(): any[] {
    return columns;
  }

  tr(key: string): Observable<string | any> {
    return this.translate.get(key);
  }

  dlgTransac = (data): void => {
    data.event.preventDefault();
    const dialogRef = this.dialog.open(TransactionModalComponent, {
      width: '380px',
      height: '414px',
      panelClass: 'transaction-modal',
      position: { top: '128px' },
      data
    });

    dialogRef.afterClosed().pipe(take(1)).subscribe((result) => {
      if (!result) {
        return;
      }
    
    });
  }

  pushTransaction(date: number, type: TransactionType, amount: number, transactions): void {
    const id = this.inArrayObjByKeys(date, type, transactions);
    if (id > -1) {
      if (amount === 0) {
        transactions.splice(id, 1);
      } else {
        transactions[id] = {
          ...transactions[id],
          amount
        };
      }
      return;
    }
    if (amount !== 0) {
      transactions.push({
        type,
        dateId: date,
        amount: +amount
      });
    }
  }

  inArrayObjByKeys(date: number, type: TransactionType, myArray: any[]): any {
    if (myArray !== undefined) {
      return myArray.findIndex(elem => {
        if (elem.dateId === date && type === elem.type) {
          return true;
        }
        return false;
      });
    }
    return -1;
  }

  toPage(pageNo: number): void {
    this.currentPage = pageNo;
  }

}
