import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { Loan } from '../../../../classes/loan';
import { SharedService } from '@app/services/shared.service';
import { v4 as uuidv4 } from 'uuid';
import { HypoService } from '@app/services/hypo.service';
import { getCurrentScenario, getScenarios } from '@app/store/scenario';
import { select, Store } from '@ngrx/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { Scenario } from 'src/classes/scenario';
import { AddLoan } from '@app/store/loan';
import { Router } from '@angular/router';
import { IScenario } from '@models';

@Component({
    selector: 'app-loan-form',
    templateUrl: './loan-form.component.html',
    styleUrls: ['./loan-form.component.css'],
    standalone: false
})
export class LoanFormComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<boolean>();
  loanForm: UntypedFormGroup;
  fset1 = true;
  fset2 = false;
  tempDoc: any;
  loans: Loan[] = [];
  scenario: Scenario;
  scenarios$: Observable<IScenario[]> = this.store.select(getScenarios);

  constructor(
    fb: UntypedFormBuilder,
    private store: Store,
    private router: Router,
    private sharedService: SharedService,
    private translate: TranslateService) {
    translate.addLangs(['en', 'fr']);
    // Specifies the current languages to the service
    translate.use('fr');
    this.loanForm = fb.group({
      hypotheque: ['', Validators.required],
      rate: ['', Validators.required],
      startsAt: [''],
      years: [''],
      freq: [''],
      scenario: [null]
    });
  }

  ngOnInit() {
    this.store.pipe(
      select(getCurrentScenario),
      takeUntil(this.destroy$)
    ).subscribe(scenario => {
      // this.scenario = scenario;
    });
  }

  ngOnDestroy(): void {
      this.destroy$.next(true);
      this.destroy$.unsubscribe();
  }

  calculateNew(year: number, locateid: number): boolean {

    if (!this.validateFields()) {
      return false;
    }
    /*
        let loan = new Loan();
        loan.init($scope.newloan);
        loan.build();
    
        $scope.loan = loan;
        $scope.totalItems = loan.payments;
    */
    return false;
  }

  async submit(value: any) {
    let frmDate = new Date();

    if (!this.loanForm.valid) {

    }

    if (value['startsAt'] === '') {
      value['startsAt'] = frmDate.toISOString().slice(0, 10);
    }

    if (value['years'] === '') {
      value['years'] = 25;
    }

    if (value['freq'] === '') {
      value['freq'] = 'm';
    }
    console.log(value);

    value.id = uuidv4();
    value.loan = +value.hypotheque;
    value.transactions = [];

    // this.scenario.addLoan();
    this.store.dispatch(AddLoan(value));
    
    // this.sharedService.changeLoan(value);
    let locale = '';
    if (this.translate.currentLang) {
      locale = this.translate.currentLang + '/';
    }
    this.router.navigate([locale + 'dashboard']);
  }

  validateFields(): boolean {
    /*
        if ($('#pret').val() < 1000 || $('#pret').val() > 5000000) {
          alert('Entrez un montant plus petit que 5 milion et plus grand que 1000 !');
          return false;
        }
        */
    /*
        var s = String($('#dt_begin').val()).replace(/-/g, '/');
        var frmDate = new Date(s);
 
        if (frmDate.toString() == 'Invalid Date' || frmDate.toString() == 'NaN') {
            alert('Entrez une date valide dans le format 2009-05-28 !');
            return false;
        }
    */
    /*
    if ($('#years').val() < 1 || $('#years').val() > 50) {
      alert('Entrez une nombre d\'annee valide !');
      return false;
    }
*/
    return true;
  }

}
