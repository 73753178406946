import { Component, AfterViewInit, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { User } from '@models';
import './getFBSDK';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from '@app/services/auth.service';
import { SharedService } from '@app/services/shared.service';
import { AuthTokenService } from '@app/services/auth-token.service';

declare const gapi: any;
declare const FB: any;

interface DialogData {
  email: string;
}

@Component({
    selector: 'app-modal-content',
    templateUrl: './login-modal.component.html',
    standalone: false
})
export class LoginModalComponent implements AfterViewInit {
  title: string;
  user: User = null;
  list: any[] = [];
  public auth2: any;
  loginForm: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<LoginModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private authService: AuthService,
    private tokenService: AuthTokenService,
    private sharedService: SharedService,
    fb: UntypedFormBuilder) {

    this.loginForm = fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  submit(value): void {
    let frmDate = new Date();

    if (!this.loginForm.valid) {
    }

    this.loginWithEmail(value);
  }

  public googleInit(): void {
    const that = this;
    gapi.load('auth2', () => {
      that.auth2 = gapi.auth2.init({
        client_id: '177987763233-r3ocrk1o4c0om0o6rbi0j14vpbg9t5up.apps.googleusercontent.com',
        cookiepolicy: 'single_host_origin',
        scope: 'profile email'
      });
      that.attachSignin(document.getElementById('googleBtn'));
    });
  }

  public attachSignin(element): void {
    const that = this;
    this.auth2.attachClickHandler(element, {}, (googleUser) => {
      this.loginWithGoogle(googleUser);
    }, (error) => {
      alert(JSON.stringify(error, undefined, 2));
    });
  }

  ngAfterViewInit(): void {
    this.googleInit();
  }

  facebookLogin(): void {
    FB.getLoginStatus((response) => {
      if (response.status === 'connected') {
        FB.api('/me', { fields: 'id,name,email,picture' }, (res) => {
          this.loginWithFacebook(res);
        });
      } else {
        FB.login((loginResponse) => {
          console.log(loginResponse);
        });
      }
    });

  }

  loginWithEmail(vals): void {
    this.authService.emailLogin(vals.email, vals.password).subscribe((user) => {
      if (user) {
        localStorage.setItem('id_token', user.token);
        this.sharedService.changeUser(user);
        this.dialogRef.close();
      }
    });
  }

  loginWithGoogle(gdata): void {
    const profile = gdata.getBasicProfile();
    const email = profile.getEmail();
    const googleId = profile.getId();
    const name = profile.getName();
    const profileImg = profile.getImageUrl();

    this.authService.googleLogin(email, name, googleId, gdata.getAuthResponse().id_token, profileImg).subscribe((user) => {
      if (user) {
        this.sharedService.changeUser(user);
        this.dialogRef.close();
      }
    });
  }

  loginWithFacebook(fdata): void {
    this.authService.facebookLogin(fdata.email, fdata.id, fdata.name, fdata.picture.data.url).subscribe((user) => {
      if (user) {
        this.sharedService.changeUser(user);
        this.dialogRef.close();
      }
    });

  }

}
